import * as React from "react"
import { graphql } from "gatsby"
import loadable from "@loadable/component"
import Comics from "../components/Comics"
import * as moment from "moment"
import { DATE_FORMAT } from "../core/constants"

const SEO = loadable(() => import("../components/seo"))
const Layout = loadable(() => import("../components/layout"))
const PageWrapper = loadable(() => import("../pageWrapper"))

export const query = graphql`
  query ComicsFocThisWeekQuery($printDate: Float!) {
    allPopularComics(filter: { printDate: { eq: $printDate } }) {
      nodes {
        title
        stockNumber
        seriesCode
        shipDate
        issue

        image {
          childImageSharp {
            fluid(maxWidth: 250) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
  }
`

export default ({ data }) => {
  const comics = data.allPopularComics.nodes

  return (
    <PageWrapper>
      <Layout>
        <SEO
          title={`Final order cutoff - FOC - This week - ${moment()
            .startOf("week")
            .day(1)
            .format(DATE_FORMAT)}`}
        />
        <Comics comics={comics} />
      </Layout>
    </PageWrapper>
  )
}
