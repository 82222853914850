import * as React from "react"
import { Menu, MenuItem, MenuSurfaceAnchor } from "@rmwc/menu"
import styled from "styled-components"
import { Chip } from "@rmwc/chip"
import { navigate } from "gatsby"
import * as moment from "moment"
import { DATE_FORMAT } from "../core/constants"

const FilterWrapper = styled.div`
  display: flex;
  margin-top: 94px;

  @media (max-width: 420px) {
    margin-top: 24px;
  }

  > div:not(:last-child) {
    margin-right: 8px;
  }
`

const StyledChip = styled(Chip)`
  background-color: ${p => (p.selected ? p.theme.primary : "transparent")};
  height: 40px;
  border-radius: 20px;
  border: 1px solid ${p => p.theme.grey300};
  color: ${p => (p.selected ? "white" : p.theme.grey700)};
`

export default props => {
  return (
    <FilterWrapper>
      <Filter
        type="release"
        label="Issues releasing"
        options={["this-weeks-comics", "next-weeks-comics"]}
      >
        <MenuItem>This week</MenuItem>
        <MenuItem>Next week</MenuItem>
      </Filter>

      <Filter
        type="foc"
        label="Final Order Cutoffs"
        options={[
          "final-order-cutoff-this-week",
          "final-order-cutoff-next-week",
        ]}
      >
        <MenuItem>This week</MenuItem>
        <MenuItem>Next week</MenuItem>
      </Filter>
    </FilterWrapper>
  )
}

const Filter: React.FC<{
  label: string
  options: string[]
  type: string
}> = props => {
  const { children, options, label, type } = props
  const [open, setOpen] = React.useState(false)

  const path = window.location.pathname.replace("/", "")
  let finalLabel = label

  if (options.includes(path)) {
    if (path === "this-weeks-comics") finalLabel = "This week's issues"
    if (path === "next-weeks-comics") finalLabel = "Next week's issues"
    if (path === "final-order-cutoff-this-week") finalLabel = "This week's FOC"
    if (path === "final-order-cutoff-next-week") finalLabel = "Next week's FOC"
  }

  const handleSelect = event => {
    navigate(`/${options[event.detail.index]}`)
  }

  const optionOne = moment()
    .startOf("week")
    .day(type == "release" ? 3 : 1)
    .format(DATE_FORMAT)

  const optionTwo = moment()
    .startOf("week")
    .day(type == "release" ? 10 : 8)
    .format(DATE_FORMAT)

  return (
    <MenuSurfaceAnchor>
      <Menu
        open={open}
        onSelect={handleSelect}
        onClose={evt => setOpen(false)}
        anchorCorner="bottomLeft"
        style={{ width: "100%" }}
      >
        <MenuItem>{optionOne}</MenuItem>
        <MenuItem>{optionTwo}</MenuItem>
      </Menu>
      <StyledChip
        onClick={evt => setOpen(!open)}
        trailingIcon="keyboard_arrow_down"
        selected={options.includes(path)}
      >
        {finalLabel}
      </StyledChip>
    </MenuSurfaceAnchor>
  )
}
