import * as React from "react"
import SearchComics from "./SearchComics"
import ComicsFilter from "./ComicsFilter"
import ComicGrid from "./ComicGrid"
import ComicGridItem from "./ComicGridItem"
import { Container } from "./Container"

export default ({ comics }) => {
  return (
    <Container>
      <SearchComics />
      <ComicsFilter />
      <ComicGrid>
        {comics.map(comic => (
          <ComicGridItem key={comic.seriesCode} comic={comic} />
        ))}
      </ComicGrid>
    </Container>
  )
}
