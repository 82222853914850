import * as React from "react"
import { Link } from "gatsby"
import * as moment from "moment"
import ComicCover from "./ComicCover"
import { baseImagePath } from "../views/Comic"

export default ({ comic }) => {
  return (
    <Link to={`/comics/${comic.seriesCode}`}>
      <ComicCover
        image={
          comic.image?.childImageSharp?.fluid ||
          `${baseImagePath}/${comic.stockNumber}.jpg`
        }
        primaryText={`${comic.title}: #${comic.issue}`}
        secondaryText={moment(comic.shipDate).format("MMMM Do, YYYY")}
      />
    </Link>
  )
}
