import * as React from "react"
import { navigate } from "gatsby"
import { SearchContainer, SearchInput, SearchIcon } from "./ListElements"
import { Menu, MenuSurfaceAnchor, MenuItem } from "@rmwc/menu"
import useFirestore from "../hooks/useFirestore"

const SearchComics = props => {
  const [input, setInput] = React.useState("")
  const [results, setResults] = React.useState([])

  const { data: comics, loading } = useFirestore({
    collection: "comics",
    orderBy: ["title", "asc"],
  })

  const handleInput = event => {
    setInput(event.target.value)
    if (input.length > 2) {
      setResults(
        comics.filter(comic =>
          comic.title.toLowerCase().includes(input.toLowerCase())
        )
      )
    }
  }

  const handleSelect = event => {
    const selectedComic = results[event.detail.index]
    navigate(`/comics/${selectedComic.seriesCode}`)
  }

  return (
    <>
      <SearchContainer>
        <SearchInput
          onChange={handleInput}
          value={input}
          placeholder="Search comics"
        />
        <SearchIcon icon="search" />
      </SearchContainer>

      <MenuSurfaceAnchor>
        <Menu
          anchorCorner="bottomLeft"
          focusOnOpen={false}
          open={results.length > 0 && input.length > 2}
          style={{ width: "100%" }}
          onSelect={handleSelect}
          onClose={() => {
            setInput("")
            setResults([])
          }}
        >
          {results.map(comic => (
            <MenuItem key={comic.seriesCode}>{comic.title}</MenuItem>
          ))}
        </Menu>
      </MenuSurfaceAnchor>
    </>
  )
}

export default SearchComics

// < input onChange = { event => setTerm(event.target.value) } value = { term } />
//   { data &&
//   data.searchSuggestions.map(comic => (
//     <Link to={`/comics/${slugify(comic.title, { lower: true })}`}>
//       <div>{comic.title}</div>
//     </Link>
//   ))}
