import * as React from "react"
import styled from "styled-components"

export default styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  grid-gap: 12px;
  width: 100%;
  margin-top: 16px;

  @media (max-width: 420px) {
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    padding: 0px;
  }
`
